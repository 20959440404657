/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { FieldType } from 'Component/Field/Field.config';
import { ProductType } from 'Component/Product/Product.config';
import ProductPrice from 'Component/ProductPrice';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import {
    AddToCart,
    FieldContainer,
    GroupedProductList,
    ProductBundleOptions,
    ProductCompareButton,
    ProductComponent as SourceProduct,
    ProductConfigurableAttributes,
    ProductCustomizableOptions,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductReviewRating,
} from 'SourceComponent/Product/Product.component';
import { formatPrice } from 'Util/Price';
import { ValidationInputTypeNumber } from 'Util/Validator/Config';

export {
    ProductReviewRating,
    ProductConfigurableAttributes,
    AddToCart,
    FieldContainer,
    ProductCustomizableOptions,
    ProductBundleOptions,
    GroupedProductList,
    ProductCompareButton,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductWishlistButton,
};

/**
 * Product
 * @class Product
 * @namespace Satisfly/Component/Product/Component */
export class ProductComponent extends SourceProduct {
    renderQuantityChanger() {
        const {
            quantity,
            minQuantity,
            maxQuantity,
            setQuantity,
            inStock,
            product: { type_id },
            step,
        } = this.props;

        if (type_id === ProductType.GROUPED) {
            return null;
        }

        return (
            <FieldContainer
              type={ FieldType.NUMBER_WITH_CONTROLS }
              attr={ {
                  id: 'item_qty',
                  name: 'item_qty',
                  defaultValue: quantity,
                  max: maxQuantity,
                  min: minQuantity,
              } }
              validationRule={ {
                  inputType: ValidationInputTypeNumber.DECIMAL,
                  isRequired: true,
                  range: {
                      min: minQuantity,
                      max: maxQuantity,
                  },
              } }
              isDisabled={ !inStock }
              mix={ { block: this.className, elem: 'Qty' } }
              events={ { onChange: setQuantity } }
              validateOn={ ['onChange'] }
              step={ step }
            />
        );
    }

    renderPrice(isPreview = false, isProductPage = false) {
        const {
            getActiveProduct, productPrice,
        } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers,
            variants,
        } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  meta
                  price={ productPrice }
                  priceType={ type }
                  tierPrices={ priceTiers }
                  isPreview={ isPreview }
                  mix={ { block: this.className, elem: 'Price' } }
                  isProductPage={ isProductPage }
                  isSchemaRequired
                  variantsCount={ variants?.length || 0 }
                />
            </div>
        );
    }

    renderWishlistButton() {
        const { magentoProduct, isWishlistEnabled } = this.props;

        if (magentoProduct.length === 0 || !isWishlistEnabled) {
            return null;
        }

        return (
            <ProductWishlistButton
              magentoProduct={ magentoProduct }
              mix={ {
                  block: this.className,
                  elem: 'WishListButton',
              } }
            />
        );
    }

    renderConfigurableOptions() {
        const {
            setActiveProduct,
            parameters,
            product: {
                type_id: type,
                variants = {},
                stock_item: {
                    is_qty_decimal: isQtyDecimal = false,
                } = {},
            },
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError,
        } = this.props;

        if (type !== ProductType.CONFIGURABLE) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="AttributesWrapper"
            >
                <ProductConfigurableAttributes
                    // eslint-disable-next-line no-magic-numbers
                  numberOfPlaceholders={ [2, 4] }
                  updateAddToCartTriggeredWithError={ updateAddToCartTriggeredWithError }
                  addToCartTriggeredWithError={ addToCartTriggeredWithError }
                  mix={ { block: this.className, elem: 'Attributes' } }
                  parameters={ parameters }
                  variants={ variants }
                  updateConfigurableVariant={ setActiveProduct }
                  configurable_options={ this.getConfigurableAttributes() }
                  isContentExpanded
                  inStock={ inStock }
                  showProductAttributeAsLink={ false }
                  isQtyDecimal={ isQtyDecimal }
                />
            </div>
        );
    }

    renderDownloadableSamples() {
        return null;
    }

    renderDownloadableLinks() {
        return null;
    }

    renderGroupedOptions() {
        return null;
    }

    renderCustomAndBundleOptions() {
        return null;
    }

    renderOmnibusBlock() {
        const {
            omnibusPrice,
            productPrice: {
                price,
            } = {},
        } = this.props;

        const { originalPrice: { value, currency } = {}, finalPrice } = price;

        if (value === finalPrice?.value) {
            return null;
        }

        const lowestPrice = !omnibusPrice || omnibusPrice === 0 ? value : omnibusPrice;

        return (
            <span block="Product" elem="Omnibus">{ __('lowest price in 30 days: %s', formatPrice(lowestPrice, currency)) }</span>
        );
    }
}

export default ProductComponent;
