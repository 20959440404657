Mosaic.addPlugins([require('/var/www/public/app/design/storefront/satisfly/node_modules/@satisfly/product-labels/src/plugin/ProductCard.plugin.js'),require('/var/www/public/app/design/storefront/satisfly/node_modules/@scandiweb/gtm/src/plugin/events/list.plugin.js')]);
/* eslint-disable no-sequences */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { createRef, Suspense } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { ProductComponent, ProductConfigurableAttributes } from 'Component/Product/Product.component';
import { ProductType } from 'Component/Product/Product.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';

import { scrollToTop } from '../../util/Browser/Browser';
import { MAX_VISIBLE_COLORS, MAX_VISIBLE_COLORS_MOBILE } from './ProductCard.config';

import './ProductCard.style';

/** @namespace Satisfly/Component/ProductCard/Component */
export class ProductCardComponent extends ProductComponent {
    static propTypes = {
        ...super.propTypes,
        omnibusPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        isRecentlyWidget: PropTypes.bool,
    };

    static defaultProps = {
        ...ProductComponent.defaultProps,
        thumbnail: '',
        linkTo: '',
        children: null,
        isLoading: false,
        mix: {},
        renderContent: null,
        hideWishlistButton: false,
        hideCompareButton: false,
        layout: CategoryPageLayout.GRID,
        omnibusPrice: '',
        isRecentlyWidget: false,
    };

    contentObject = {
        renderCardLinkWrapper: this.renderCardLinkWrapper.bind(this),
        pictureBlock: {
            picture: this.renderPicture.bind(this),
        },
        content: {
            productPrice: this.renderPrice.bind(this),
            mainDetails: this.renderMainDetails.bind(this),
            additionalProductDetails: this.renderBrand.bind(this),
        },
    };

    imageRef = createRef();

    className = 'ProductCard';

    state = {
        hoverImage: null,
        isHovering: false,
    };

    __construct(props) {
        super.__construct?.(props);

        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    handleLinkClick() {
        const {
            isPlp,
            device: {
                isMobile,
            },
            registerSharedElement,
        } = this.props;

        if (isMobile) {
            registerSharedElement(this.imageRef);
        }

        if (!isPlp) {
            scrollToTop();
        }
    }

    renderEmptyProductPrice() {
        return (
            <div
              block="ProductCard"
              elem="PriceWrapper"
              mods={ { isEmpty: true } }
            />
        );
    }

    renderCardContent() {
        const { renderContent, product: { name }, device: { isMobile } = {} } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <div
                  block="ProductCard"
                  elem="LinkInnerWrapper"
                  mods={ { loaded: !!name } }
                  onMouseEnter={ () => !isMobile && this.setState({ isHovering: true }) }
                  onMouseLeave={ () => !isMobile && this.setState({ isHovering: false }) }
                >
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                            { this.renderMobileWishlistBtn() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderName(false) }
                        { this.renderPrice() }
                        { this.renderOmnibusBlock() }
                        { this.renderColors() }
                    </div>
                    <div block="ProductCard" elem="VisibleOnHover">
                        { this.renderVisibleOnHover() }
                    </div>
                </div>
            ))
        );
    }

    renderPrice() {
        const {
            getActiveProduct,
            product: {
                type_id: baseType,
            } = {},
            inStock,
        } = this.props;

        const {
            price_range: priceRange,
        } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        if (baseType === ProductType.CONFIGURABLE && !inStock) {
            return null;
        }

        return super.renderPrice();
    }

    renderMobileWishlistBtn() {
        const { device } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return this.renderProductActions();
    }

    renderVisibleOnHover() {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <div block="ProductCard" elem="Footer">
                { this.renderAddToCart() }
                { this.renderProductActions() }
            </div>
        );
    }

    renderProductActions() {
        return (
            <div block="ProductCard" elem="ProductActions">
                { this.renderProductCardWishlistButton() }
            </div>
        );
    }

    renderProductCardWishlistButton() {
        const { hideWishlistButton, isWishlistEnabled } = this.props;

        if (hideWishlistButton || !isWishlistEnabled) {
            return null;
        }

        return this.renderWishlistButton();
    }

    renderMainDetails() {
        const { product: { name } } = this.props;

        return (
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ name } length={ TextPlaceHolderLength.MEDIUM } />
            </p>
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const { linkTo = '', product: { url } } = this.props;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                >
                    { children }
                </div>
            );
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ this.handleLinkClick }
              onClickBeforeLoader={ this.handleLinkClick }
              mix={ mix }
            >
              { children }
            </Link>
        );
    }

    renderConfigurableOptions() {
        const {
            setActiveProduct,
            parameters,
            product: { type_id: type, variants = [] },
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError,
        } = this.props;

        if (
            type !== ProductType.CONFIGURABLE
            || !Object.keys(this.getConfigurableAttributes()).length
            || !inStock
        ) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="AttributesWrapper"
            >
                <Suspense fallback={ null }>
                    <ProductConfigurableAttributes
                      // eslint-disable-next-line no-magic-numbers
                      numberOfPlaceholders={ [2, 4] }
                      updateAddToCartTriggeredWithError={ updateAddToCartTriggeredWithError }
                      addToCartTriggeredWithError={ addToCartTriggeredWithError }
                      mix={ { block: this.className, elem: 'Attributes' } }
                      parameters={ parameters }
                      variants={ variants }
                      updateConfigurableVariant={ setActiveProduct }
                      configurable_options={ this.getConfigurableAttributes() }
                      isContentExpanded
                      inStock={ inStock }
                      showProductAttributeAsLink={ false }
                    />
                </Suspense>
            </div>
        );
    }

    renderCardListContent() {
        const {
            children, layout, renderContent, product: { name }, device: { isMobile } = {},
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper((
            <div
              block="ProductCard"
              elem="Link"
              onMouseEnter={ () => !isMobile && this.setState({ isHovering: true }) }
              onMouseLeave={ () => !isMobile && this.setState({ isHovering: false }) }
            >
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        { this.renderPicture() }
                    </figure>
                </div>
                <div block="ProductCard" elem="Content" mods={ { layout } }>
                    <div block="ProductCard" elem="MainInfo">
                        { this.renderMainDetails() }
                    </div>
                    <div block="ProductCard" elem="AttributeWrapper">
                        { this.renderPrice() }
                        { this.renderOmnibusBlock() }
                        { this.renderConfigurableOptions() }
                    </div>
                    <div block="ProductCard" elem="ActionWrapper" mods={ { loaded: !!name } }>
                        { this.renderAddToCart() }
                        { this.renderProductActions() }
                    </div>
                    { !!children && (
                        <div block="ProductCard" elem="AdditionalContent">
                            { children }
                        </div>
                    ) }
                </div>
            </div>
        ));
    }

    handleImageHover = (item = null) => {
        this.setState({
            hoverImage: item?.image_path || null,
        });
    };

    renderColors() {
        const { product: { linked_products, sku }, device: { isMobile } = {} } = this.props;

        if (!linked_products || linked_products?.length <= 1) {
            return null;
        }

        const maxColors = isMobile ? MAX_VISIBLE_COLORS_MOBILE : MAX_VISIBLE_COLORS;
        const finalColors = linked_products?.length > maxColors ? linked_products.slice(0, maxColors) : linked_products;

        return (
            <div block="ProductCard" elem="Colors">
                { finalColors.map((item) => (
                    <span
                      block="ProductCard"
                      elem="LinkedColorWrapper"
                      onMouseOver={ () => this.handleImageHover(item) }
                      onMouseOut={ () => this.handleImageHover(null) }
                      mods={ { isActive: sku === item.sku } }
                      key={ item.sku }
                    >
                        <span
                          block="ProductCard"
                          elem="LinkedColor"
                          style={ { backgroundImage: `url(${item.image_path})` } }

                        />
                    </span>
                )) }
                { linked_products?.length > maxColors && (
                    <span block="ProductCard" elem="MoreColors">
                        +
                        { linked_products?.length - maxColors }
                    </span>
                ) }
            </div>
        );
    }

    getConfigurableAttributes() {
        const filteredOptions = super.getConfigurableAttributes();

        return Object.fromEntries(
            Object.entries(filteredOptions).filter(
                ([, option]) => {
                    const { attribute_options } = option;

                    if (!attribute_options) {
                        return false;
                    }

                    return Object.values(attribute_options)
                        .some(({ swatch_data: swatchData }) => swatchData);
                },
            ),
        );
    }

    requiresConfiguration() {
        const {
            parameters,
            product: {
                type_id: type,
                options = [],
                items = [],
                links_purchased_separately,
            },
        } = this.props;

        const configureBundle = type === ProductType.BUNDLE;

        const allAttrs = super.getConfigurableAttributes();
        const plpConfigurableAttrs = this.getConfigurableAttributes();

        const isConfigurable = type === ProductType.CONFIGURABLE;

        const configureConfig = isConfigurable && (
            (
                Object.keys(allAttrs).length
                !== Object.keys(plpConfigurableAttrs).length
            )
            || (
                Object.values(plpConfigurableAttrs).some(
                    (value) => value.attribute_values.length === 0,
                )
            )
            || (Object.keys(allAttrs).length > 0 && Object.keys(parameters).length === 0)
        );

        const configureGrouped = type === ProductType.GROUPED
            && (items).every(({ qty }) => qty === 0);

        const configureCustomize = options.some(({ required = false }) => required);

        const configureDownloadableLinks = ProductType.DOWNLOADABLE && links_purchased_separately === 1;

        return configureGrouped
            || configureBundle
            || configureConfig
            || configureCustomize
            || configureDownloadableLinks
            || isConfigurable;
    }

    renderAddToCart() {
        const {
            layout,
            showSelectOptionsNotification,
            inStock,
        } = this.props;

        const requiresConfiguration = this.requiresConfiguration();

        if (inStock && requiresConfiguration) {
            return (
                    <button
                      block="Button AddToCart"
                      mods={ { layout } }
                      onClick={ showSelectOptionsNotification }
                    >
                        { __('To cart') }
                    </button>
            );
        }

        if (!inStock) {
            return (
                <div block="ProductCard" elem="OutOfStock">
                    <p>
                        { __('Out of stock') }
                    </p>
                </div>
            );
        }

        return this.renderAddToCartButton(layout);
    }

    renderPicture(mix = {}) {
        const {
            product: { id, name },
            thumbnail,
            hover_image = null,
            onLoad,
        } = this.props;

        const {
            hoverImage,
            isHovering,
        } = this.state;

        const displayedPicture = isHovering ? hover_image : thumbnail;

        this.sharedComponent = (
            <Image
              imageRef={ this.imageRef }
              src={ hoverImage || displayedPicture }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
              onImageLoad={ onLoad }
              key={ id }
            />
        );

        return (
            <>
                { this.sharedComponent }
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ displayedPicture }
                />
            </>
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            layout,
            product: { variants, sku },
        } = this.props;

        const firstVariantSku = variants?.[0]?.sku || sku;

        if (layout === CategoryPageLayout.LIST) {
            return (
                <li
                  block="ProductCard"
                  mods={ { layout } }
                  mix={ mix }
                >
                    <div data-product-id={ firstVariantSku }>
                        <Loader isLoading={ isLoading } />
                        { this.renderCardListContent() }
                    </div>
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ { layout } }
              mix={ mix }
            >
                <div data-product-id={ firstVariantSku }>
                    <Loader isLoading={ isLoading } />
                    { this.renderCardContent() }
                    { !!children && (
                        <div block="ProductCard" elem="AdditionalContent">
                            { children }
                        </div>
                    ) }
                </div>
            </li>
        );
    }
}

export default ProductCardComponent;
